<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Checkout completed</h4>
                </div>

                <div class="grid">
                    <div class="col-12 md:col-6 xl:col-3">
                        <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                <span class="overview-icon">
                                    <font-awesome-icon
                                        title='Totali'
                                        :icon="['fas', 'database']"
                                    />
                                </span>
                            <span class="overview-title">Totali</span>
                            <div class="grid overview-detail">
                                <div class="col-6">
                                    <div class="overview-number">{{ totalLastHour }}</div>
                                    <div class="overview-subtext">Ultima ora</div>
                                </div>
                                <div class="col-6">
                                    <div class="overview-number">{{ total24Hour }}</div>
                                    <div class="overview-subtext">Ultime 24 ore</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 xl:col-3">
                        <div class="card no-gutter widget-overview-box widget-overview-box-2">
                                <span class="overview-icon">
                                    <font-awesome-icon
                                        title='Web'
                                        :icon="['fab', 'chrome']"
                                    />
                                </span>
                            <span class="overview-title">Web</span>
                            <div class="grid overview-detail">
                                <div class="col-6">
                                    <div class="overview-number">{{webLastHour}}</div>
                                    <div class="overview-subtext">Ultima ora</div>
                                </div>
                                <div class="col-6">
                                    <div class="overview-number">{{webLast24Hour}}</div>
                                    <div class="overview-subtext">Ultime 24 ore</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 xl:col-3">
                        <div class="card no-gutter widget-overview-box widget-overview-box-3">
                                <span class="overview-icon">
                                    <font-awesome-icon
                                        title='Android'
                                        :icon="['fab', 'android']"
                                    />
                                </span>
                            <span class="overview-title">Android</span>
                            <div class="grid overview-detail">
                                <div class="col-6">
                                    <div class="overview-number">{{androidLastHour}}</div>
                                    <div class="overview-subtext">Ultima ora</div>
                                </div>
                                <div class="col-6">
                                    <div class="overview-number">{{androidLast24Hour}}</div>
                                    <div class="overview-subtext">Ultime 24 ore</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 xl:col-3">
                        <div class="card no-gutter widget-overview-box widget-overview-box-4">
                                <span class="overview-icon">
                                    <font-awesome-icon
                                        title='Apple'
                                        :icon="['fab', 'apple']"
                                    />
                                </span>
                            <span class="overview-title">Ios</span>
                            <div class="grid overview-detail">
                                <div class="col-6">
                                    <div class="overview-number">{{ iosLastHour }}</div>
                                    <div class="overview-subtext">Ultima ora</div>
                                </div>
                                <div class="col-6">
                                    <div class="overview-number">{{ iosLast24Hour }}</div>
                                    <div class="overview-subtext">Ultime 24 ore</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='grid'>
                    <div class='col-12'>
                        <div class='card'>
                            <div class='card-header'>
                                <h5>Checkout completed ultime 24 ore progressive</h5>
                            </div>
                            <GChart type="ColumnChart" :data='chartData' :options='optionsValues'/>
                        </div>

                    </div>
                </div>
                <div class='grid'>
                    <div class='col-12'>
                        <div class='card'>
                            <div class='card-header'>
                                <h5>Checkout completed ultime 24 ore per slot</h5>
                            </div>
                            <GChart type="ColumnChart" :data='deltaData' :options='optionsValues'/>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('Stats/CheckoutCompleted');
import { GChart } from "vue-google-charts";
export default {
    components: {
        GChart
    },
    data(){
        return {
            chartData: [],
            deltaData: [],
            optionsValues: {
                chart: {
                    title: 'Checkouts completed progressivi nel tempo',
                    subtitle: "Ultime 24 ore"
                }
            }
        }
    },
    computed: {
        ...mapGetters(['allGetLastValue', 'loading', 'androidGetLastValue', 'iosGetLastValue', 'webGetLastValue',
            'all_values', 'web_values', 'ios_values', 'android_values', 'all_deltas', 'ios_deltas', 'android_deltas', 'web_deltas']),
        totalLastHour(){
            return this.allGetLastValue(1);
        },
        total24Hour(){
            return this.allGetLastValue(24);
        },
        webLastHour(){
            return this.webGetLastValue(1);
        },
        webLast24Hour(){
            return this.webGetLastValue(24);
        },
        iosLastHour(){
            return this.iosGetLastValue(1);
        },
        iosLast24Hour(){
            return this.iosGetLastValue(24);
        },
        androidLastHour(){
            return this.androidGetLastValue(1);
        },
        androidLast24Hour(){
            return this.androidGetLastValue(24);
        }
    },
    watch: {
        total24Hour(){
            console.log("DRAW GRAPH");
            this.rebuildData();
        }
    },
    mounted(){
        this.loadData();
    },
    methods: {
        ...mapActions(['loadStatsData']),
        loadData(){
            this.loadStatsData().then(() => {
                this.rebuildData();
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        rebuildData(){
            const data =  [
                ['Ora', 'Totale', 'Web', 'Android', 'Ios'],
            ];
            const keys = Object.keys(this.all_values);
            keys.forEach(key => {
                data.push([
                    key.slice(-2),
                    this.all_values[key] || 0,
                    this.web_values[key] || 0,
                    this.android_values[key] || 0,
                    this.ios_values[key] || 0
                ])
            })
            this.chartData = data;

            const dKeys = Object.keys(this.all_deltas);
            const dData = [
                ['Ora', 'Totale', 'Web', 'Android', 'Ios'],
            ];
            dKeys.forEach(key => {
                dData.push([
                    key.slice(-2),
                    this.all_deltas[key] || 0,
                    this.web_deltas[key] || 0,
                    this.android_deltas[key] || 0,
                    this.ios_deltas[key] || 0
                ]);
            });
            this.deltaData = dData;
        }
    }
}
</script>
