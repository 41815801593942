<template>
    <div class='grid'>
        <div class='col-12'>
            <Started/>
        </div>
        <div class='col-12'>
            <Completed/>
        </div>
    </div>
</template>
<script>
import Completed from './Completed.vue';
import Started from './Started.vue';
export default {
    components: {
        Completed,
        Started
    }
}
</script>
